import * as React from 'react';
import { ModalModalProps } from '@elsa-zku/lib/components/Modals/components/ModalModal';
import Form, { FormCommonError } from '@elsa-zku/lib/components/Form';
import { useDispatch, useSelector } from 'react-redux';
import { ReduxState } from 'src/redux/emptyState';
import FormField from '@elsa-zku/lib/components/Form/components/FormField';
import { makeFormField } from '@elsa-zku/lib/components/Form/helpers/makeFormFields';
import { identity } from 'lodash';
import { createTemplate, updateTemplate } from 'src/redux/actions';

export const inputFormField = makeFormField(
  (props: React.HTMLProps<HTMLInputElement>) => <input className="form-control" {...props} />,
  { parse: identity, initialValue: '' },
);

export type Props = ModalModalProps & {
  templateId?: number
}

const TemplateInfo: React.FC<Props> = (props) => {
  const { templateId, resolve } = props;
  const template = useSelector((state: ReduxState) => templateId ? state.templatesDict[templateId] : null);
  const dispatch = useDispatch<any>();

  return (
    <div>
      <Form
        initialValues={React.useMemo(() => template ? ({
          name: template.name,
          code: template.code,
        }) : null, [template])}
        onSubmit={React.useCallback((data) => {
          return dispatch(templateId ? updateTemplate(templateId, data) : createTemplate(data)).then(() => resolve());
        }, [])}
        render={React.useCallback(() => {
          return (
            <>
              <div className="mb-2">
                <label htmlFor="skipFirstLines">Название</label>
                <FormField {...inputFormField({ name: 'name' })} />
              </div>
              <div className="mb-2">
                <label htmlFor="skipFirstLines">Код</label>
                <FormField {...inputFormField({ name: 'code' })} />
              </div>
              <FormCommonError/>
              <button className="btn btn-success">Сохранить</button>
            </>
          );
        }, [])}/>

    </div>
  );
};

export default React.memo(TemplateInfo);
