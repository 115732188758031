import _get from 'lodash/get';
import * as React from 'react';
import classnames from 'classnames';
import { connect, ResolveThunks } from 'react-redux';
import FileUpload from 'src/components/FileUpload';
import { createTemplateFile, getFileContent, removeFile } from 'src/helpers/fetch';
import toColumnName from 'src/helpers/toColumnName';
import { replaceTemplate, updateFile, updateFilesList, updateTemplate } from 'src/redux/actions';
import { ReduxState } from 'src/redux/emptyState';
import styles from './FilesList.scss';
import { ApiFile, ApiTemplate } from 'src/types';
import { ModalModalProps } from '@elsa-zku/lib/components/Modals/components/ModalModal/ModalModal';
import extractHttpError, { getHttpError } from '@elsa-zku/lib/helpers/extractHttpError';
import isFileObject, { isNotFileObject } from 'src/helpers/isFileObject';
import { openConfirm } from '@elsa-zku/lib/components/Modals/helpers/modalOpener';
import { Link } from 'react-router-dom';
import { generatePreviewTable } from 'src/helpers/generatePreviewTable';

type OwnProps = ModalModalProps & {
  customActionLabel?: string,
  selected?: number,
};
type StoreProps = ReturnType<typeof mapStateToProps>;
type StoreDispatch = typeof mapDispatchToProps;
type Props = OwnProps & StoreProps & ResolveThunks<StoreDispatch>;
type State = {
  selectedFile: number,
  loading: boolean,
};

class FilesList extends React.PureComponent<Props, State> {
  state: State = {
    selectedFile: this.props.selected || null,
    loading: false,
  };

  onSelect = (selectedFile: number) => this.setState({ selectedFile });
  onDeselect = () => this.setState({ selectedFile: null });

  onRemoveFile = (file: ApiFile) => {
    const modal = openConfirm({
      title: 'Вы действительно хотите удалить файл?',
      acceptText: 'Удалить',
    });
    modal.result.then(() => {
      removeFile(file).then(() => {
        this.setState({ selectedFile: null });
        this.props.updateFilesList();
      }, (error) => {
        const message = getHttpError(error);
        alert(message.common);
      });
    });
  };

  onAddToTemplate = (file: ApiFile) => {
    const { replaceTemplate, selectedTemplate, resolve } = this.props;

    createTemplateFile(selectedTemplate.code, file.id).then((template) => {
      replaceTemplate(template);
    });
    // console.log(templateFile)
    // const template: Partial<ApiTemplate> = {
    //   files: [...selectedTemplate.files, templateFile],
    // };
    // if (!selectedTemplate.files.length) template.head = file.table[0];
    //
    // updateTemplate(selectedTemplate._id, template);
    resolve();
  };

  onCustomAction = (file: ApiFile) => {
    this.props.resolve(file);
  };

  updateFile(data: Partial<ApiFile>) {
    const { selectedFile } = this.state;
    const { updateFile } = this.props;

    this.setState({ loading: true });
    updateFile(selectedFile, data).then(
      () => this.setState({ loading: false }),
      (error: any) => {
        const message = _get(error, ['response', 'data', 'error']) || error;
        alert(message);
        this.setState({ loading: false });
      },
    );
  }

  onToggleSkipFirstRow = (e: React.ChangeEvent<HTMLInputElement>) => this.updateFile({ skipFirstRow: e.target.checked });
  onChangeSkipFirstLines = (e: React.ChangeEvent<HTMLInputElement>) => this.updateFile({ skipFirstLines: +e.target.value });
  onChangeSkipLastLines = (e: React.ChangeEvent<HTMLInputElement>) => this.updateFile({ skipLastLines: +e.target.value });
  onToggleDownload = (e: React.ChangeEvent<HTMLInputElement>) => this.updateFile({ downloadBeforeProcess: e.target.checked });
  onChangeEncoding = (e: React.ChangeEvent<HTMLSelectElement>) => this.updateFile({ encoding: e.target.value });
  onChangeFormat = (e: React.ChangeEvent<HTMLSelectElement>) => this.updateFile({ format: e.target.value });
  onChangeDelimiter = (e: React.ChangeEvent<HTMLInputElement>) => this.updateFile({ delimiter: e.target.value });
  onChangeNewline = (e: React.ChangeEvent<HTMLSelectElement>) => this.updateFile({ newline: e.target.value });
  onChangeQuoteChar = (e: React.ChangeEvent<HTMLInputElement>) => this.updateFile({ quoteChar: e.target.value });
  onChangeRowSelector = (e: React.ChangeEvent<HTMLInputElement>) => this.updateFile({ rowSelector: e.target.value });

  renderFile = (fileId: string) => {
    const file = this.props.filesDict[fileId];
    const { selectedFile } = this.state;
    const isActive = selectedFile === file.id;
    const onClick = () => this.onSelect(file.id);
    return (
      <div
        key={file.id}
        className={classnames(styles.listItem, file.fileRemoved && styles.listItemRemoved, isActive && styles.listItemActive)}
        onClick={onClick}
        title={file.originalName}
      >
        {file.type === 'FILE' && <i className="far fa-file"/>}
        {file.type === 'LINK' && <i className="fas fa-link"/>}
        <div className={styles.fileName}>{file.originalName}</div>
      </div>
    );
  };

  renderTable(table: string[][]) {
    const colsCount = table[0] ? table[0].length : 0;
    if (!colsCount) return null;

    const cols = [];
    for (let i = 0; i < colsCount; i++) {
      cols.push(
        <div key={i} className={styles.tableCol}>
          <div className={classnames(styles.tableCell, styles.tableHead)}>{toColumnName(i)} | {i + 1}</div>
          {table.map((row, j) => <div key={j} className={styles.tableCell}>{row[i]}</div>)}
        </div>,
      );
    }

    return <div className={styles.table}>{cols}</div>;
  }

  renderJsons(rows: Record<string, string>[]) {
    if (!rows) return null;
    return rows.map((row, i) => {
      return <pre key={i}>{JSON.stringify(row, null, 2)}</pre>;
    });
  }

  renderInfo() {
    const { selectedFile, loading } = this.state;
    const { selectedTemplate, customActionLabel, templatesDict, templates } = this.props;
    const file = this.props.filesDict[selectedFile];

    const usedTemplates = templates.reduce((list, templateId) => {
      const template = templatesDict[templateId];
      if (template.files.findIndex(f => f.fileId === file.id) !== -1) list.push(template);
      return list;
    }, [] as ApiTemplate[]);

    return (
      <div className={styles.info}>
        <div className={styles.block}>
          <div className={styles.left}>
            <div className={styles.row}>
              <div className={styles.label}>Имя файла:</div>
              <div className={classnames(styles.value, styles.break)}>{file.originalName}</div>
            </div>
            <div className={styles.row}>
              <div className={styles.label}>Тип:</div>
              <div className={styles.value}>
                {file.type === 'FILE'
                  ? <React.Fragment><i className="far fa-file"/> Файл</React.Fragment>
                  : <React.Fragment><i className="fas fa-link"/> Ссылка</React.Fragment>
                }
              </div>
            </div>

            <div className={styles.row}>
              <div className={styles.label}>Дата загрузки:</div>
              <div className={styles.value}>{file.createdAt}</div>
            </div>

            <div className={styles.row}>
              <div className={styles.label}>Дата изменения:</div>
              <div className={styles.value}>{file.updatedAt}</div>
            </div>

            <div>
              <h6>Используется в шаблонах:</h6>
              {usedTemplates.length ? (
                <ul>
                  {usedTemplates.map(t => <li key={t.id}><Link to={`/${t.id}`}><i>#{t.id}</i> {t.name}</Link></li>)}
                </ul>
              ) : <div className="mb-3">-</div>}
            </div>

            <div className={styles.row}>
              {file.type === 'LINK'
                ? <a href={file.link} target="_blank">Скачать файл</a>
                : file.fileRemoved ? (
                  <div className={styles.row}><b className="text-danger">Физический файл удален!</b></div>
                ) : (
                  <a href={file.href} target="_blank" download={file.originalName}>Скачать файл</a>
                )
              }
              <button
                className="btn btn-link ml-2"
                onClick={() => {
                  getFileContent(file).then(
                    response => {
                      const w = window.open('', '_blank');
                      w.document.body.innerHTML = generatePreviewTable(response);
                    },
                    (e) => alert(e.message),
                  );
                }}>
                Посмотреть содержимое файла
              </button>
            </div>
          </div>
          <div className={styles.right}>
            {selectedTemplate && (
              <button className="btn btn-success btn-sm btn-block" onClick={() => this.onAddToTemplate(file)}>
                Добавить в шаблон
              </button>
            )}
            {customActionLabel && (
              <button className="btn btn-success btn-sm btn-block" onClick={() => this.onCustomAction(file)}>
                {customActionLabel}
              </button>
            )}

            {file.type === 'LINK' && (
              <div className="mt-2">
                <label>
                  <input
                    type="checkbox" checked={file.downloadBeforeProcess || false} onChange={this.onToggleDownload}
                  />
                  {' '}Скачать перед обработкой
                </label>
              </div>
            )}

            <div>
              <label htmlFor="encoding">Кодировка</label>
              <select id="encoding" className="form-control" value={file.encoding} onChange={this.onChangeEncoding}>
                <option value="UTF-8">UTF-8</option>
                <option value="WINDOWS-1251">WINDOWS-1251</option>
              </select>
            </div>

            <div className="mt-2">
              <label htmlFor="format">Формат</label>
              <select id="format" className="form-control" value={file.format || ''} onChange={this.onChangeFormat}>
                {!file.format && <option value="">---</option>}
                <option value="xml">xml</option>
                <option value="csv">csv</option>
                <option value="xls">xls</option>
                <option value="xlsx">xlsx</option>
                <option value="1c">1С</option>
                <option value="dbf">dbf</option>
              </select>
            </div>

            {file.format === 'csv' && (
              <React.Fragment>
                <div className="mt-2">
                  <label htmlFor="delimited">Разделитель</label>
                  <input
                    id="delimited"
                    className="form-control"
                    value={file.delimiter}
                    onChange={this.onChangeDelimiter}
                  />
                </div>
                <div className="mt-2">
                  <label htmlFor="newline">Перенос строки</label>
                  <select id="newline" className="form-control" value={file.newline} onChange={this.onChangeNewline}>
                    <option value="">---</option>
                    <option value="\r\n">\r\n</option>
                    <option value="\n">\n</option>
                    <option value="\r">\r</option>
                  </select>
                </div>
                <div className="mt-2">
                  <label htmlFor="quoteChar">Символ экранирования</label>
                  <input
                    id="quoteChar"
                    className="form-control"
                    value={file.quoteChar}
                    onChange={this.onChangeQuoteChar}
                  />
                </div>
              </React.Fragment>
            )}

            {file.format === 'xml' && (
              <div className="mt-2">
                <label htmlFor="delimited">Тег строки</label>
                <input
                  id="delimited"
                  className="form-control"
                  value={file.rowSelector || ''}
                  onChange={this.onChangeRowSelector}
                />
              </div>
            )}

            {isNotFileObject(file) && (
              <>
                <div className="mt-2">
                  <label htmlFor="skipFirstLines">Пропустить строк в начале</label>
                  <input
                    type="number"
                    className="form-control"
                    id="skipFirstLines"
                    value={file.skipFirstLines || 0}
                    onChange={this.onChangeSkipFirstLines}
                  />
                </div>

                <div className="mt-2">
                  <label htmlFor="skipFirstLines">Пропустить строк в конце</label>
                  <input
                    type="number"
                    className="form-control"
                    id="skipFirstLines"
                    value={file.skipLastLines || 0}
                    onChange={this.onChangeSkipLastLines}
                  />
                </div>
              </>
            )}

          </div>
        </div>
        {isFileObject(file)
          ? this.renderJsons(file.preview as Record<string, string>[])
          : this.renderTable(file.preview as string[][])
        }

        <button className="btn btn-danger btn-sm " onClick={() => this.onRemoveFile(file)}>Удалить файл</button>
        {loading && <div className={styles.loading}><i className="fas fa-spinner fa-spin"/></div>}
      </div>
    );
  }

  render() {
    const { files } = this.props;
    const { selectedFile } = this.state;

    return (
      <div className={styles.container}>
        <div className={styles.aside}>
          <div className={styles.listHeader}>Список файлов:</div>
          <div className={styles.list}>
            {Array.isArray(files) && files.map(this.renderFile)}
          </div>
          <button className="btn btn-primary" onClick={this.onDeselect}>Добавить файл</button>
        </div>
        <div className={styles.content}>
          {selectedFile ? this.renderInfo() : <FileUpload onUpload={this.onSelect}/>}
        </div>
      </div>
    );
  }
}


const mapStateToProps = (state: ReduxState) => ({
  files: state.files,
  filesDict: state.filesDict,
  templates: state.templates,
  templatesDict: state.templatesDict,
  selectedTemplate: state.selectedTemplate && state.templatesDict && state.templatesDict[state.selectedTemplate],
});
const mapDispatchToProps = { updateFile, updateTemplate, updateFilesList, replaceTemplate };
export default connect<StoreProps, StoreDispatch, OwnProps>(mapStateToProps, mapDispatchToProps)(FilesList);
