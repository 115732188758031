import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect, ResolveThunks } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TemplateName from 'src/components/TemplateName';
import FilesList from '../FilesList';
import { ReduxState } from 'src/redux/emptyState';
import styles from 'src/components/Header/Header.scss';
import { openModal } from '@elsa-zku/lib/components/Modals/helpers/modalOpener';
import TestTemplatePopup from 'src/components/TestTemplatePopup';
import TemplateInfo from 'src/components/TemplateInfo';
import { selectTemplate } from 'src/redux/actions';

const getFileLink = (templateCode: string) => `${document.location.origin}/api/templates/${templateCode}`;

type OwnProps = {};
type StoreProps = ReturnType<typeof mapStateToProps>;
type StoreDispatch = typeof mapDispatchToProps;
type Props = OwnProps & StoreProps & ResolveThunks<StoreDispatch>;
type State = {};

class Header extends React.PureComponent<Props, State> {
  onShowFilesList = () => {
    openModal({
      size: 1024,
      renderBody: (props) => <FilesList {...props} />,
    });
  };

  onEdit = () => {
    openModal({
      title: 'Редактирование шаблона',
      render: (props) => <TemplateInfo templateId={this.props.selectedTemplate} {...props} />,
    });
  };

  onTest = () => {
    const { selectedTemplate, template } = this.props;
    openModal({
      // size: 1024,
      title: 'Тестирование шаблона',
      render: (props) => <TestTemplatePopup templateCode={template.code}/>,
    });
  };

  onExport = () => {
    const { selectedTemplate, template } = this.props;
    window.open(`${document.location.origin}/api/templates/${selectedTemplate}/export`);
  };

  // renderTemplateName() {
  //   const { template } = this.props;
  //   if (!template) return null;
  //   return (
  //     <div className={styles.templateName}>
  //       {template.name}{' '}
  //       <button className="btn btn-link btn-sm">
  //         <i className="far fa-edit" />
  //       </button>
  //     </div>
  //   );
  // }

  renderAside() {
    const { selectedTemplate, template } = this.props;
    const link = getFileLink(template.code);

    return (
      <div className={styles.aside}>
        <button className="btn btn-sm btn-info" onClick={this.onEdit}>
          <i className="far fa-edit"/>
        </button>
        <div className={styles.input}>
          <div className="input-group">
            <input type="text" className={`form-control form-control-sm `} disabled value={link}/>
            <div className="input-group-append">
              <CopyToClipboard text={link} onCopy={() => this.setState({ copied: true })}>
                <button className="btn btn-primary btn-sm"><i className="fas fa-copy"/></button>
              </CopyToClipboard>
            </div>
          </div>
        </div>
        <button className="btn btn-info btn-sm ml-2" onClick={this.onTest}>
          <i className="fas fa-upload"/> Протестировать
        </button>
        {/*<button className="btn btn-success btn-sm ml-2" onClick={this.onDownload}>*/}
        {/*  <i className="fas fa-download"/> Скачать*/}
        {/*</button>*/}
        <button className="btn btn-success btn-sm ml-2" onClick={this.onExport}>
          <i className="fas fa-download"/> Экспортировать
        </button>
      </div>
    );
  }

  render() {
    const { selectedTemplate, template } = this.props;

    return (
      <div className={styles.wrapper}>
        {!!template && (
          <Link className={`btn btn-outline-light btn-sm mr-3 flex-shrink-0`} to="/">
            <i className="fas fa-chevron-left"/> К выбору шаблона
          </Link>
        )}
        <button className={`btn btn-primary btn-sm`} onClick={this.onShowFilesList}>
          Файлы
        </button>
        {!!template && <TemplateName templateId={selectedTemplate}/>}
        {!!template && this.renderAside()}
      </div>
    );
  }
}

const mapStateToProps = (state: ReduxState) => ({
  selectedTemplate: state.selectedTemplate,
  template: state.templatesDict && state.templatesDict[state.selectedTemplate],
});
const mapDispatchToProps = {};
export default connect<StoreProps, StoreDispatch, OwnProps>(mapStateToProps, mapDispatchToProps)(Header);
